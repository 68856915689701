
import { del, get, post, put } from "../modules/ms-api.module";

const getWorkflows = async function() {
    const brut = await get({
        uri: "/workflow/lister",
        need_token: true,

    });    
    const data = await brut.json();
    if (brut.status === 200) return data.result;
    else return [];
}

const deleteWorkflow = async function (id_workflow) {
    const brut = await del({
        need_token: true,
        parameters: [{
            "id_workflow": `${id_workflow}`
        }],
        uri: "/workflow/"
    });
    const data = await brut.json();
    if (brut.status === 200) return data.result;
    else return [];
}

const getExecutablesTaches = async function (id_logement) {
    const brut = await get({
        need_token: true,
        uri: "/workflow/liste_etapes_executables",
        parameters: [{"id_logement": `${id_logement}`}]
    })
    const data = await brut.json();
    if (brut.status === 200) return data.result;
    else return [];
}

const getAllEtapes = async function () {
    const brut = await get({
        need_token: true,
        uri: "/etape/etapes",
    });
    const data = await brut.json();
    if (brut.status === 200) return data.result;
    else return [];
}

const createWorkflow = async function (libelle, droits) {
    const brut = await post({
        need_token: true,
        body: {
            libelle: `${libelle}`,
            droits: `${JSON.stringify(droits)}`
        },
        uri: "/workflow/ajouter_workflow"
    });
    if (brut.status === 200) return true;
    else return false;
}

const createEtape = async function (libelle) {
    const brut = await post({
        need_token: true, 
        uri: "/etape/ajouter_etape",
        body: {
            libelle: `${libelle}`
        }
    })
    if (brut.status === 200) return true;
    else return false;
}

const updateWorkflow = async function(id_workflow, etapes) {
    const brut = await put({
        uri: "/workflow/",
        need_token: true,
        body: {
            id_workflow: `${id_workflow}`,
            etapes: `${JSON.stringify(etapes)}`
        }
    });
    return brut.status === 200;
}

const changeHour = async function(id_workflow, heure_echeance) {
    const brut = await put({
        uri: "/workflow/horaire_echeance",
        need_token: true,
        parameters: [{"id_workflow": `${id_workflow}`}, {"heure_echeance": `${heure_echeance}`}]
    })
    return brut.status === 200;
}
const workflow_service = {changeHour, updateWorkflow, createEtape, createWorkflow, getAllEtapes, getExecutablesTaches, deleteWorkflow, getWorkflows};
export default workflow_service;