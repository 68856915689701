import { useEffect, useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import Navbar from '../components/Navbar';
import FiltreEcheance from '../components/Elements/FiltreEcheance';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigate } from 'react-router-dom';
import FiltreVue from '../components/Elements/FiltreVue';
import ListeParWorkflows from '../components/Vues/ListeParWorkflows';
import ExecuteNextStepModale from '../components/Modales/ExecuteNextStepModale';
import Footer from '../components/Footer';
import { css_darkBlue, css_white } from '../constants/Colors';
import Titre from '../components/Titre';
import Sidebar from '../components/Sidebar';
import DeleteWorkflowModale from '../components/Modales/DeleteWorkflowModale';
import auth_service from '../services/auth.service';
import programmation_service from '../services/programmation.service';
import logement_service from '../services/logement.service';
import tache_service from '../services/tache.service';
import LogementAccueil from '../components/Elements/LogementAccueil';
import moment from 'moment-timezone';
import Recapitulatif from "./Recapitulatif";
import Logement from './Logement';
import { screenWidth } from '../constants/Const';
import { sortRemainingLogementToday, sortRemainingLogementTomorrow, sortRemainingLogementWeek } from '../constants/Sort';
import NotificationModale from '../components/Modales/NotificationModale';

export default function Accueil({seen_by = undefined}) {
  const [loading, setLoading] = useState(false);
  const [workflows, setWorkflows] = useState([]);
  const [logements, setLogements] = useState([]);
  const [remainingLogements, setRemainingLogements] =  useState([]);

  //filtres
  const [echeance, setEcheance] = useState("Aujourd'hui");
  const [selectedWorkflow, setSelectedWorkflow] = useState(-1);
  
  const [selectedTache, setSelectedTache] = useState(undefined);
  const [selectedAction, setSelectedAction] = useState(undefined);
  const [loadingProgrammation, setLoadingProgrammation] = useState(-1)

  const [selectedValidateur, setSelectedValidateur] = useState(-1);
  const [enableSidebar, setEnableSidebar] = useState(false);
  const navigate = useNavigate()

  //admin
  const [displayRecap, setDisplayRecap] = useState(false);
  const [displayLogement, setDisplayLogement] = useState(false);
  const [selectedIdLogement, setSelectedIdLogement] = useState(undefined);
  const [selectedIdProgrammation, setSelectedIdProgrammation] = useState(undefined);

  useEffect(() => {
    if(!auth_service.isConnected()) navigate("/");
    setLoading(true)
    if (seen_by === undefined) { // On ne l'exécute pas si on est en administrateur
      const promises = [];
      promises.push(AsyncStorage.getItem("workflow"));
      promises.push(AsyncStorage.getItem("echeance"));
      Promise.all(promises).then((r) => {
        if (r[0]) setSelectedWorkflow(parseInt(r[0]))
        if (r[1]) setEcheance(r[1]);
        if (workflows.length === 0 && logements.length === 0) fetch();
      });
    }
    
  }, []);

  useEffect(() => {
    if (seen_by !== undefined) fetch()
  }, [seen_by])

  const fetch = () => {
    setLoading(true);
    logement_service.getAttributedLogements(seen_by).then((res) => {
      setWorkflows(res.workflows);
      setLogements(res.logements);
      setRemainingLogements(getRemainingLogements(res.logements, res.workflows, echeance));
      setLoading(false)
    })
  }

  const getRemainingLogements = (logements, workflows, echeance) => {
    let response = [];
    if (echeance === "Aujourd'hui") response = logements.filter(l => !workflows.filter(w => moment(w.date).tz('Europe/Paris').isSame(moment.tz('Europe/Paris'), 'day')).flatMap(item => item.logements).map(l => l.id_logement).includes(l.id_logement)).sort(sortRemainingLogementToday);
    if (echeance === "Demain") response = logements.filter(l => !workflows.filter(w => moment(w.date).tz('Europe/Paris').isSame(moment.tz('Europe/Paris').add(1, 'd'), 'day')).flatMap(item => item.logements).map(l => l.id_logement).includes(l.id_logement)).sort(sortRemainingLogementTomorrow);
    if (echeance === "Semaine") response = logements.filter(l => !workflows.filter(w => moment(w.date).tz('Europe/Paris').isAfter(moment.tz('Europe/Paris').add(1, 'd'), 'day')).flatMap(item => item.logements).map(l => l.id_logement).includes(l.id_logement)).sort(sortRemainingLogementWeek);
    return response;
  }

  const changeEcheance = (e) => {
    setEcheance(e);
    setRemainingLogements(getRemainingLogements(logements, workflows, e))
    AsyncStorage.setItem('echeance', e);
  }

  const changeSelectedWorkflow = (w) => {
    setSelectedWorkflow(w);
    AsyncStorage.setItem('workflow', w);
  }
  
  const executeNextStep = (tache) => {
    setLoadingProgrammation(tache.id_programmation)
    setSelectedTache(undefined)
    setSelectedAction(undefined)
    tache_service.changerEtat({
        id_programmation: tache.id_programmation,
        id_logement: tache.id_logement,
        id_validateur: selectedValidateur
    }).then((r) => {
      fetch();
      setLoadingProgrammation(-1);
      setSelectedValidateur(-1)
    })
  }    

  const disableTask = (tache) => {
      setLoadingProgrammation(tache.id_programmation)
      setSelectedTache(undefined)
      setSelectedAction(undefined)
      programmation_service.deleteProgrammation({
          scheduled: tache.scheduled,
          id_programmation: tache.id_programmation,
          id_logement: tache.id_logement,
          id_workflow:tache.id_workflow
      }).then((r) => {

          fetch();
          setLoadingProgrammation(-1)
      })
  }

  const sendNotification = (id_programmation) => {
    setLoadingProgrammation(id_programmation);
    setSelectedTache(undefined);
    setSelectedAction(undefined);
    programmation_service.sendNotification(id_programmation)
    .then((r) => {
      setLoadingProgrammation(-1)

    })
  }

  if (loading) {
    return (
      <>
        <Navbar />
        <View style={styles.container}>
          <View   style={styles.middleofpage}>
          <Titre  texte="Chargement en cours ..." 
                  size={1.5}
                  color={css_darkBlue}
                  fontWeight={700}
                  align='center'/>
          </View>
        </View>
      </>
    );
  }
  else if (displayRecap) {
    return (<Recapitulatif  seen_by={seen_by} 
                            setDisplayRecap={setDisplayRecap} />);
  }
  else if (displayLogement) {
    return (<Logement seen_by={seen_by}
                      setDisplayLogement={setDisplayLogement}
                      selected_id_logement={selectedIdLogement}
                      selected_id_programmation={selectedIdProgrammation} />);
  } else {
    return (
      <>
        <Navbar sidebarEnabled={enableSidebar}
                setEnableSidebar={setEnableSidebar} />
        <View style={enableSidebar ? [styles.container, styles.sidebarEnable] : styles.container}>
          <FiltreVue  loading={loading}
                      sidebarEnabled={enableSidebar}
                      setEnableSidebar={setEnableSidebar} 
                      iconSideBar={seen_by === undefined}
                      seen_by={seen_by}
                      setDisplayRecap={setDisplayRecap} />
          <FiltreEcheance loading={loading} 
                          enableWorkflowsFilter={true}
                          echeance={echeance}
                          refreshOnMod={loadingProgrammation}
                          setEcheance={changeEcheance}
                          setSelectedWorkflow={changeSelectedWorkflow}
                          selectedWorkflow={selectedWorkflow}
                          seen_by={seen_by}/>

          {/* Liste des workflows triés et affichés par workflows */}
          
          <ListeParWorkflows  display={!loading}
                              echeance={echeance}
                              workflows={workflows}
                              logements={logements}
                              selectedWorkflow={selectedWorkflow}
                              setLoadingProgrammation={setLoadingProgrammation}
                              loadingProgrammation={loadingProgrammation}
                              setSelectedAction={setSelectedAction}
                              setSelectedTache={setSelectedTache}
                              setSelectedIdLogement={setSelectedIdLogement}
                              setSelectedIdProgrammation={setSelectedIdProgrammation}
                              setDisplayLogement={setDisplayLogement}
                              seen_by={seen_by}
                          />
                          
        <View style={styles.remainingLogements} >
          <Titre  texte={`Aucune tâche à effectuer pour :`}
                  fontWeight={500}
                  marginLeft={3}
                  size={1.1}/>
            {remainingLogements.map((l, key) => {
              return (
              <View style={{marginLeft: "2%"}} key={`LogementAccueil${l.id_logement}${key}`} >
                <LogementAccueil  infos={l}
                                  date_echeance={moment.tz("Europe/Paris")}
                                  expandEnable={false}
                                  remainingLogements={{
                                    "Aujourd'hui": l.est_vacant[0],
                                    "Demain": l.est_vacant[1],
                                    "Semaine": {
                                      checkin: l.est_vacant[2].checkin || l.est_vacant[3].checkin || l.est_vacant[4].checkin || l.est_vacant[5].checkin || l.est_vacant[6].checkin || l.est_vacant[7].checkin,
                                      checkout: l.est_vacant[2].checkout || l.est_vacant[3].checkout || l.est_vacant[4].checkout || l.est_vacant[5].checkout || l.est_vacant[6].checkout || l.est_vacant[7].checkout,
                                      occupied: l.est_vacant[2].occupied || l.est_vacant[3].occupied || l.est_vacant[4].occupied || l.est_vacant[5].occupied || l.est_vacant[6].occupied || l.est_vacant[7].occupied
                                    }
                                  }}
                                  filterEcheance={echeance}
                                  setSelectedIdLogement={setSelectedIdLogement}
                                  setDisplayLogement={setDisplayLogement}
                                  seen_by={seen_by} />
              </View>                          
              )
            })}
          </View>
        </View>

        {selectedTache !== undefined && selectedAction === "next"? 
          <ExecuteNextStepModale  onValidation={() => {executeNextStep(selectedTache)}}
                                  selectedUser={selectedValidateur}
                                  setSelectedUser={setSelectedValidateur}
                                  id_programmation={selectedTache.id_programmation}
                                  disappear={() => setSelectedTache(undefined)}/> :<></>}
        {selectedTache !== undefined && selectedAction === "delete"? 
          <DeleteWorkflowModale onValidation={() => {disableTask(selectedTache)}}
                                disappear={() => setSelectedTache(undefined)}/> :<></>}

        {selectedTache !== undefined && selectedAction === "notify"?
          <NotificationModale   onValidation={() => {sendNotification(selectedTache.id_programmation)}}
                                id_programmation={selectedTache.id_programmation}
                                disappear={() => {setSelectedTache(undefined)}} />:<></>}

      <Footer   sidebarEnabled={enableSidebar}/>
      <Sidebar  sidebarEnabled={enableSidebar}
                setEnableSidebar={setEnableSidebar}/>
      </>
    );
  }

}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: css_white,
    alignItems: 'center',
    paddingBottom: "5vh",
    minWidth: Math.min(screenWidth, 500),
    maxWidth: Math.min(screenWidth, 500),
    minHeight: "100vh"
  },
  remainingLogements: {
    width:Math.min(screenWidth, 500)
  },
  middleofpage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sidebarEnable: {
    marginLeft: screenWidth <=500 ? "-60vw" : 0
  }
});
