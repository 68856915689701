import { getToken } from "../modules/localStorage.module";

/**
 * Méthode GET permettant de récupérer des informations depuis le MS-API.
 * @param {*} param0 
 * @returns 
 */
export const get = async ({need_token = false, uri = "/", parameters = [], on = "ms-api"}) => {
    return await Promise.resolve(custom_fetch({
        need_token: need_token,
        method: "GET", 
        uri: uri, 
        parameters: parameters,
        on:on
    }))
    .then((r) => {
        return r;
    })
    .catch((err) => {
        return err;
})
}

/**
 * Méthode POST permettant de crééer des valeurs en BDD par le MS-API.
 * @param {*} param0 
 */
export const post = async ({need_token = true, uri = "/", body = undefined, headers = undefined, parameters = [], on = "ms-api"}) => {
    return await Promise.resolve(custom_fetch({method:"POST",need_token:need_token,uri:uri,body:body,headers:headers, parameters:parameters, on:on}))
    .then((r) => {
        return r;
    })
    .catch((err) => {
        return err;
    })
}

/**
 * Méthode PUT permettant de modifier des valeurs en BDD par le MS-API.
 * @param {*} param0 
 */
export const put = async ({need_token = true, uri = "/", body = undefined, headers = undefined, parameters = [], on = "ms-api"}) => {
    return await Promise.resolve(custom_fetch({method:"PUT",need_token:need_token,uri:uri,body:body,headers:headers,parameters:parameters,on:on}))
    .then((r) => {
        return r;
    })
    .catch((err) => {
        return err;
    })
}

/**
 * Méthode DELETE permettant de supprimer des valeurs en BDD par le MS-API.
 * @param {*} param0 
 */
export const del = async ({need_token = true, uri = "/", body = undefined, headers = undefined, parameters = [], on = "ms-api"}) => {
    return await Promise.resolve(custom_fetch({method:"DELETE",need_token:need_token,uri:uri,body:body,headers:headers,parameters: parameters, on:on}))
    .then((r) => {
        return r;
    })
    .catch((err) => {
        return err;
    })
}


/**
 * Méthode privée générique permettant d'effectuer le fetch.
 * @param {*} param0 
 * @returns 
 */
const custom_fetch = async ({method = "GET", uri = "/", parameters = [], body = undefined, need_token = false, headers = undefined, on = "ms-api"}) => {
    let options = {method: method};
    // On ajoute le header au besoin
    if (headers !== undefined) options = {...options, headers: headers};
    // On ajoute le body au besoin
    if (body !== undefined) {
        if (options.headers !== undefined) {
            options = {...options, body: JSON.stringify(body)};
            options.headers = {...options.headers, 'Content-Type': 'application/json'};
        }
        else {
            options = {...options, body: JSON.stringify(body), headers: { 'Content-Type': 'application/json' }};
        }
    }
    
    // On ajoute le token au besoin
    if (need_token) {
        const token = getToken();
        if (token === null) return "L'utilisateur n'est pas connecté.";
        if (options.headers !== undefined) options.headers = {...options.headers, 'authorization': 'Bearer '+ token};
        else options = {...options, headers: {'authorization': 'Bearer '+ token}};
    } 

    // On prépare les paramètres
    let finalParam = "";
    if (parameters.length > 0 ) {
        finalParam += "?"
        for (let i = 0; i < parameters.length; i++) {
            finalParam += `${Object.keys(parameters[i])[0]}=${Object.values(parameters[i])[0]}&`
        }
         
    }
    
    // On exécute le fetch
    /*return await Promise.resolve(fetch(`https://${on === "ms-api" ? "api.dev.mercinestor.fr":"photo.mercinestor.fr"}${uri}${finalParam}`, options))
    .then((data) => {return data;})
    .catch((err) => {return err;});*/
    return await Promise.resolve(fetch(`https://${on === "ms-api" ? "api.dev.mercinestor.fr":"photo.dev.mercinestor.fr"}${uri}${finalParam}`, options))
    .then((data) => {return data;})
    .catch((err) => {return err;});

}

