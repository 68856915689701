import { Pressable,StyleSheet, View, Text} from "react-native";
import moment from 'moment-timezone';
import { css_green, css_grey, css_orange, css_red, css_white } from "../../constants/Colors";
import { MaterialIcons,Ionicons } from '@expo/vector-icons';
import { useNavigate } from "react-router-dom";

/**
 * Composant représentant un workflow sur la page de Logement.
 * @param {*} param0 
 * @returns 
 */
export default function Workflow({
    id_logement = undefined,
    id_programmation = undefined, 
    destinataires = [], 
    validateur=undefined,
    libelle = "",
    no_action=false,
    color = "", 
    date_change = moment.tz("Europe/Paris"), 
    date_echeance = moment.tz("Europe/Paris"), 
    selected = false, 
    onValidation = null, 
    onDeletion = null,
    onNotification = null, 
    nom_createur = "", 
    loading = false, 
    display = true, 
    extended = true, 
    extendable = false, 
    onExtend= null, 
    seen_by = undefined,
    setSelectedIdLogement = () => true,
    setSelectedIdProgrammation= () => true,
    setDisplayLogement= () => true
    }) {
    
    const navigate = useNavigate();
    const goToLogementPage = (id_logement, id_programmation) => {
        if (seen_by === undefined) navigate(`/logement?which=${id_logement}&prog=${id_programmation}`);
        else {setDisplayLogement(true); setSelectedIdLogement(id_logement); setSelectedIdProgrammation(id_programmation);}
    }

    const styles = StyleSheet.create({
        workflow: {
    
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: color === "green" ? css_green
                        : color === "red" ? css_red
                        : color === "orange" ? css_orange
                        : color === "green" ? css_green
                        : css_grey
            ,
            backgroundColor: css_white,
            borderRadius: "15px",
            width: "90%",
            marginTop: "2%",
            marginBottom: "2%"
        },
        row: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        rowRightFixed: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginRight: "2%"
        },
        textWorkflow: {
            display: "flex",
            flexDirection: "column",
            marginLeft: "2%",
            marginTop: "1vh",
            marginBottom: "1vh",
            width: "60%"
        },
        titre: {
            color: color === "green" ? css_green
                    : color === "red" ? css_red
                    : color === "orange" ? css_orange
                    : color === "green" ? css_green
                    : css_grey,
            fontSize: "1rem",
            textDecorationLine: color === "deleted" ? "line-through": "none"
        },
        
        colorFont: {
            color: color === "green" ? css_green
                    : color === "red" ? css_red
                    : color === "orange" ? css_orange
                    : color === "green" ? css_green
                    : css_grey,
            fontSize: "0.7rem",
            textDecorationLine: color === "deleted" ? "line-through": "none"
        },
        containerButtons: {
            width: "35%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            //alignItems: "center",
            alignContent: "flex-end",
            justifyContent: "space-between",
        },
        workflowActions: {
            fontSize: "1.2rem",
            opacity:0.9,
            color: css_grey
        },
        workflowActionsMore: {
            //marginLeft: "15vw",
            marginRight: "3%"
        },
        workflowActionsChanged: {
            width: "1rem",
            height: "1rem",
            opacity:0.9,
            filter: `invert(76%) sepia(69%) saturate(12%) hue-rotate(352deg) brightness(91%) contrast(85%)`,  
        },
        state: {
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
            alignItems: "center",
        },
        dateContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end"
        },
        warning: {
            fontSize: "1rem",
            marginRight: "2%",
            color: css_red
          },
        stateFont: {
            color: css_grey,
            fontSize: "0.7rem",
            marginLeft: "4vw"
        },
        changed: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
        },
        colorFontChanged: {
            color: color === "green" ? css_green
                    : color === "red" ? css_red
                    : color === "orange" ? css_orange
                    : color === "green" ? css_green
                    : css_grey,
            fontSize: "0.7rem",
        },
        loadingText: {
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
            fontWeight: 600,
            color: css_grey
        },
        loading: {
            opacity: 0.5
        }
    });

    if (!display) {
        return (
            <></>
        );
    } else {
        return (
        <Pressable onPress={() => {if(extendable) {onExtend()}}} style={styles.workflow}>
                {loading ? 
                    <Text style={styles.loadingText}>Modifications en cours ...</Text>
                :<></>
                }
                <View style={loading ? [styles.row, styles.loading] : styles.row}>
                    <View style={styles.textWorkflow} >
                        {/*Nom du workflow*/}
                        <View style={styles.state}>
                            <Text style={styles.titre}>{libelle}</Text>
                            <Text style={styles.stateFont}>{color === "green" ? "Validée" : color === "deleted" ? "Supprimée" : ""}</Text>
                        </View>
    
                        {/*Liste de personnes attribuées*/}
                        {extended ?
                        <Text style={styles.colorFont}>
                            {/*t.destinataire.map((d, key) => {return (<Text>{`${d.utilisateur}${t.destinataire.length-1 === key ? "" :", "}`}</Text>)})*/}
                            {destinataires.length !== 0  && color !== "green" && color !== "deleted"? 
                                <Text>
                                    {`${destinataires[0].utilisateur}${destinataires.length === 1 ? "" :`, +${destinataires.length-1}`}`}
                                </Text> : <></>}
                            {validateur !== undefined  && (color === "green" || color !== "deleted")? 
                            <Text>
                                {`${validateur.utilisateur}`}
                            </Text> : <></>}
                        </Text>
                        : <></>}
    

                        {/*Affichage de la date d'échéance ou de changement si green */}
                        {extended ?
                        <View>
                            {color === "green" ?
                                <Text style={styles.colorFont}>{moment(date_change).tz("Europe/Paris").isSame(moment.tz("Europe/Paris"), "day") ? "Aujourd'hui" 
                                                                : moment(date_change).tz("Europe/Paris").isSame(moment.tz("Europe/Paris").add(1, "day"), "day") ? "Demain"
                                                                : moment(date_change).tz("Europe/Paris").format("dddd D MMMM YYYY")}</Text>
                                :
                                <View style={styles.dateContainer}>
                                    {moment(date_echeance).tz("Europe/Paris").isBefore(moment.tz("Europe/Paris"), "day") && color === "red"? 
                                        
                                        <Ionicons   name='warning-outline'
                                                    style={[styles.warning]} />
                                        :<></>}
                                    <Text style={styles.colorFont}>{moment(date_echeance).tz("Europe/Paris").isSame(moment.tz("Europe/Paris"), "day") ? "Aujourd'hui" 
                                                                :moment(date_echeance).tz("Europe/Paris").isSame(moment.tz("Europe/Paris").add(1, "day"), "day") ? "Demain"
                                                                : moment(date_echeance).tz("Europe/Paris").isSame(moment.tz("Europe/Paris").subtract(1, "day"), "day") ? "Hier"
                                                                : moment(date_echeance).tz("Europe/Paris").format("dddd D MMMM YYYY")}</Text>
                                
                                </View>}
                        </View>:<></>}
                    </View>
    

                    {!no_action && extended?
                    <View style={styles.containerButtons}>
                        {color === "red" ?
                            <Pressable onPress={() => {onNotification()}} style={styles.actionMarginLeft} >
                                <MaterialIcons  name='warning'
                                                style={[styles.workflowActions]} />
                            </Pressable>
                        : <View></View>}
                        {color === "red" ?
                            <Pressable onPress={() => {onValidation()}} style={styles.actionMarginLeft} >
                                <MaterialIcons  name='check'
                                                style={[styles.workflowActions]} />
                            </Pressable>
                        : <View></View>}
                        {color !== "green" && color !== "deleted" ?
                            <Pressable onPress={() => {onDeletion()}} style={styles.actionMarginLeft} >
                                <MaterialIcons  name='delete'
                                                style={[styles.workflowActions]} />
                                     
                            </Pressable>
                        :<></>}
                        {id_logement !== undefined && id_programmation !== undefined ? 
                        <Pressable onPress={() => {goToLogementPage(id_logement,id_programmation)}} style={styles.actionMarginLeft} >
                                <Ionicons   name='calendar-outline'
                                            style={[styles.workflowActions, styles.workflowActionsMore]} />
                                     
                        </Pressable>:<></>}
                    </View>:<></>}
                </View>
                <View style={styles.rowRightFixed}>
                    {(color=== "green" || color === "deleted" ) && extended? 
                        <View style={styles.changed}>
                            <MaterialIcons  source={color=== "green" ? "check" : "delete"}
                                            style={styles.workflowActionsChanged} />
                            <Text style={styles.colorFontChanged}>{nom_createur} - </Text>
                            <Text style={styles.colorFontChanged}>{moment(date_change).tz("Europe/Paris").isSame(moment.tz("Europe/Paris"), "day") ? 
                                                                    moment(date_change).tz("Europe/Paris").format("HH:mm") : 
                                                                    `${moment(date_change).tz("Europe/Paris").format("dddd D [à] HH[h]mm")}`}</Text>
                    </View>:<></>}         
                </View>
        </Pressable>
        );
    }
    

}




