import moment from "moment";

export const sortTaches = function (a, b) {
    if (a.case === b.case) {
        return sortByDateEcheance(a,b);
    }
    if (a.case === "red") return -1
    if ((a.case === "green" || a.case === "deleted") && (b.case === "red")) return 1
    if (a.case === "orange" && (b.case === "green" || b.case === "deleted" || b.case === "red")) return 1
    if (a.case === "grey" && (b.case === "green" || b.case === "deleted" || b.case === "red" || b.case === "orange")) return 1

    return -1;
}
export const sortByCountEmptyOrNotToday = function (a, b) {
    if (a.etats_workflows.filter(ew => ew.case === "red" || ew.case === "deleted" || ew.case === "green").length > 0) return -1;
    return 0
}
export const sortByCountEmptyOrNotTomorrow = function (a, b) {
    if (a.etats_workflows.filter(ew => ew.case === "orange").length > 0) return -1;
    return 0
}
export const sortByCountEmptyOrNotWeek = function (a, b) {
    if (a.etats_workflows.filter(ew => ew.case === "grey").length > 0) return -1;
    return 0
}
export const sortbyTachesbyAlphabetic = function (a, b) {
    if (a.case === b.case) {
        return a.libelle > b.libelle ? 1 : a.libelle < b.libelle ? -1 : 0;
    }
    if (a.case === "red") return -1
    if ((a.case === "green" || a.case === "deleted") && (b.case === "red")) return 1
    if (a.case === "orange" && (b.case === "green" || b.case === "deleted" || b.case === "red")) return 1
    if (a.case === "grey" && (b.case === "green" || b.case === "deleted" || b.case === "red" || b.case === "orange")) return 1

    return -1;
}
export const sortLogement = function (a, b) {
    if (a.state === "green" || a.state === "deleted") return 1
    if (a.state === "red") return -1
    if (a.state === "orange" && b.state === "red") return 1
    if (a.state === "grey" && (b.state === "red" || b.state === "orange")) return 1
    return 1;
}

export const sortByDateEcheance = function (a, b) {
    if (moment(a.date_echeance) === moment(b.date_echeance)) return 0;
    if (moment(a.date_echeance).isAfter(moment(b.date_echeance))) return 1;
    return -1
}
export const sortByDate = function (a, b) {
    if (moment(a.date) === moment(b.date)) return 0;
    if (moment(a.date).isAfter(moment(b.date))) return 1;
    return -1
}

export const sortRemainingLogementToday = function (a, b) {
    if ((a.est_vacant[0].checkin || a.est_vacant[0].checkout || a.est_vacant[0].occupied) && (b.est_vacant[0].checkin || b.est_vacant[0].checkout || b.est_vacant[0].occupied)) return 0;
    if (a.est_vacant[0].checkin || a.est_vacant[0].checkout || a.est_vacant[0].occupied ) return -1;
    if (b.est_vacant[0].checkin || b.est_vacant[0].checkout || b.est_vacant[0].occupied ) return 1;
    return 0;
}
export const sortRemainingLogementTomorrow = function (a, b) {
    if ((a.est_vacant[1].checkin || a.est_vacant[1].checkout || a.est_vacant[1].occupied) && (b.est_vacant[1].checkin || b.est_vacant[1].checkout || b.est_vacant[1].occupied)) return 0;
    if (a.est_vacant[1].checkin || a.est_vacant[1].checkout || a.est_vacant[1].occupied ) return -1;
    if (b.est_vacant[1].checkin || b.est_vacant[1].checkout || b.est_vacant[1].occupied ) return 1;
    return 0;
}
export const sortRemainingLogementWeek = function (a, b) {
    if ((a.est_vacant[2].checkin || a.est_vacant[2].checkout || a.est_vacant[2].occupied) && (b.est_vacant[2].checkin || b.est_vacant[2].checkout || b.est_vacant[2].occupied)) return 0;
    if (a.est_vacant[2].checkin || a.est_vacant[2].checkout || a.est_vacant[2].occupied ) return -1;
    if (b.est_vacant[2].checkin || b.est_vacant[2].checkout || b.est_vacant[2].occupied ) return 1;
    return 0;
}