import { Pressable, StyleSheet, Text, View } from "react-native";
import { css_black, css_calendar_font_grey, css_darkBlue, css_grey } from "../../constants/Colors";
import { useState } from "react";
import moment from "moment-timezone";
import { useEffect } from "react";
import JourDuCalendrier from "./JourDuCalendrier";
import { Feather } from '@expo/vector-icons';

/**
 * Composant Calendrier permettant d'afficher l'élément Calendrier.
 * @param {*} param0 
 * @returns 
 */
export default function Calendrier({
    executePreviousMonth = () => {},
    executeNextMonth = () => {}, 
    selected_date = undefined, 
    addTask = false, 
    reservations = [], 
    taches = [], 
    isSelectable = false, 
    onDateSelection = null, 
    loading = false, 
    displayByFullMonth = false, 
    currentMonth = moment.tz("Europe/Paris"),
    selectedBegin = moment.tz("Europe/Paris"), 
    selectedEnd = moment.tz("Europe/Paris").add(1, 'w')
}) {
    const [weeks, setWeeks] = useState([])
    const [selectedDate, setSelectedDate] = useState(undefined);
    
    const refresh = () => {
        let w = addDates()
        w = addReservations(w);
        w = addTaches(w);
        setWeeks(w)
    }
    const onSelection = (date) => {
        if (addTask) {
            onDateSelection(date)
            setSelectedDate(date)
        } else {
            onDateSelection(selectedDate === undefined ? date 
                            : moment(date).tz("Europe/Paris").isSame(moment(selectedDate).tz("Europe/Paris"), "day") 
                            ? undefined : date)
            setSelectedDate(selectedDate === undefined ? date 
                            : moment(date).tz("Europe/Paris").isSame(moment(selectedDate).tz("Europe/Paris"), "day")
                            ? undefined : date)
        }
        
    }

    useEffect(() => {
        onDateSelection(addTask ? selectedDate : undefined)
        setSelectedDate(
            addTask ? selectedDate : undefined
        )
    }, [addTask])

    useEffect(() => {
        onSelection(selected_date);
    }, [selected_date]);

    const addReservations = (tmp) => {
        if(tmp.length !== 0){
            // On ajoute les réservations
            for (let r = 0; r < reservations.length; r++) {
                for (let w = 0; w < (moment(selectedEnd).tz("Europe/Paris").diff(moment(selectedBegin).tz("Europe/Paris"), 'w')+1); w++) {
                    for (let d = 0; d < tmp[w].length; d++) {
                        
                        if(moment(reservations[r].date_arrivee).tz("Europe/Paris").isSame(moment(tmp[w][d].date).tz("Europe/Paris"), "day")) {
                            tmp[w][d] = {
                                day: tmp[w][d].day,
                                date: tmp[w][d].date,
                                today: tmp[w][d].today,
                                past: tmp[w][d].past,
                                checkin: true,
                                checkout: tmp[w][d].checkout,
                                occupied: tmp[w][d].occupied,
                                taches: tmp[w][d].taches
                            }
                        }
                        if (moment(reservations[r].date_depart).tz("Europe/Paris").isSame(moment(tmp[w][d].date).tz("Europe/Paris"), "day")) {
                            tmp[w][d] = {
                                day: tmp[w][d].day,
                                date: tmp[w][d].date,
                                today: tmp[w][d].today,
                                past: tmp[w][d].past,
                                checkin: tmp[w][d].checkin,
                                checkout: true,
                                occupied: tmp[w][d].occupied,
                                taches: tmp[w][d].taches
                            }
                        }
                        if (moment(reservations[r].date_depart).tz("Europe/Paris").isAfter(moment(tmp[w][d].date).tz("Europe/Paris"), "day") 
                            && moment(reservations[r].date_arrivee).tz("Europe/Paris").isBefore(moment(tmp[w][d].date).tz("Europe/Paris"), "day")) {
                                tmp[w][d] = {
                                    day: tmp[w][d].day,
                                    date: tmp[w][d].date,
                                    today: tmp[w][d].today,
                                    past: tmp[w][d].past,
                                    checkin: tmp[w][d].checkin,
                                    checkout: tmp[w][d].checkout,
                                    occupied: true,
                                    taches: tmp[w][d].taches
                                }
                        }
                    }
                }
            }
        }
        return tmp
    }

    const addTaches = (tmp) => {
        if(tmp.length !== 0){
            //On ajoute les tâches
            for (let t = 0; t < taches.length; t++) {
                for (let w = 0; w < (moment(selectedEnd).tz("Europe/Paris").diff(moment(selectedBegin).tz("Europe/Paris"), 'w')+1); w++) {
                    for (let d = 0; d < tmp[w].length; d++) {
                        if(moment(taches[t].date_echeance).tz("Europe/Paris").isSame(moment(tmp[w][d].date).tz("Europe/Paris"), "day")) {
                           tmp[w][d].taches = tmp[w][d].taches.concat(taches[t]) 
                        }
                    }
                }
            }
        }
        return tmp
    }

    const addDates = () => {

        let tmpDate = moment(selectedBegin).tz("Europe/Paris").clone();
        let tmpEndDate = moment(selectedEnd).tz("Europe/Paris").clone();
        let countWeeks = tmpEndDate.diff(tmpDate, 'w')+1;
        
        let w = [];
        let tmp = [];
        // On ajoute les jours passés
        for (let i = 0; i < countWeeks; i++) {
            tmp = []
            for (let j = 0; j <= 6;  j++) {
                tmp.push({
                    day: tmpDate.clone().add(i, 'w').add(j, 'day').format("D"),
                    date: tmpDate.clone().add(i, 'w').add(j, 'day'),
                    today: tmpDate.clone().add(i, 'w').add(j, 'day').isSame(moment.tz("Europe/Paris"), 'day'),
                    past: (!displayByFullMonth && tmpDate.clone().add(i, 'w').add(j, 'day').isBefore(moment.tz("Europe/Paris"), "day"))
                        || (displayByFullMonth && moment(currentMonth).tz("Europe/Paris").month() !== tmpDate.clone().add(i, 'w').add(j, 'day').month()),
                    checkin: false,
                    checkout: false,
                    occupied: false,
                    taches: []
                })
            }
            w.push(tmp) 
        }
        return w
        
    }

    useEffect(() => {
        setSelectedDate(undefined);
        onDateSelection(undefined)
        refresh();
    }, [reservations, taches, displayByFullMonth, currentMonth, selectedBegin, selectedEnd])

    if (!displayByFullMonth) {
        return (
            <View style={styles.container}>
                <View style={styles.row}>
                    <Text style={styles.chevrons}>
                        <Pressable  onPress={(e) => {e.preventDefault();executePreviousMonth()}} >
                            <Feather name="chevrons-left" style={styles.monthFilterText} />
                        </Pressable>
                    </Text>
                    <Text style={styles.monthInitiale}>{`4 prochaines semaines`}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.dateInitiale}>L</Text>
                    <Text style={styles.dateInitiale}>M</Text>
                    <Text style={styles.dateInitiale}>M</Text>
                    <Text style={styles.dateInitiale}>J</Text>
                    <Text style={styles.dateInitiale}>V</Text>
                    <Text style={styles.dateInitiale}>S</Text>
                    <Text style={styles.dateInitiale}>D</Text>
                </View>
                {loading ? <View style={styles.loading}><Text style={styles.loadingText}>Chargement...</Text></View>:<></>}
                {loading ? <></> :
                weeks.map((week, key) => {
                    return (
                        <View   style={styles.row}
                                key={`CalendrierSemaine${key}`}>
                            {week.map((d, key) => {
                                return (
                                    <JourDuCalendrier 
                                        key={`${key}WeekOfCalendar${d.day}`} 
                                        day={d.day}
                                        date={d.date}
                                        today={d.today}
                                        past={d.past}
                                        occupied={d.occupied}
                                        checkin={d.checkin}
                                        checkout={d.checkout}
                                        taches={d.taches}
                                        isSelectable={isSelectable}
                                        shadowed={addTask ? !moment(selectedDate).tz("Europe/Paris").isSame(moment(d.date).tz("Europe/Paris"), "day") : (selectedDate !== undefined && !moment(selectedDate).tz("Europe/Paris").isSame(moment(d.date).tz("Europe/Paris"), "day"))}
                                        selected={addTask ? moment(selectedDate).tz("Europe/Paris").isSame(moment(d.date).tz("Europe/Paris"), "day") : (selectedDate !== undefined && moment(selectedDate).tz("Europe/Paris").isSame(moment(d.date).tz("Europe/Paris"), "day"))}
                                        onSelection={() => {onSelection(d.date)}} />
                                    
                                )
                            })  }
                        </View>
                            );
                        })
                        
                }
    
            </View>
        );
    } else {
        return (
            <View style={styles.container}>
                <View style={styles.row}>
                    <Text style={styles.chevrons}>
                        <Pressable  onPress={(e) => {e.preventDefault();executePreviousMonth()}} >
                            <Feather name="chevrons-left" style={styles.monthFilterText} />
                        </Pressable>
                    </Text>
                    <Text style={styles.monthInitiale}>{`${moment(currentMonth).tz("Europe/Paris").format("MMMM")[0].toUpperCase()}${moment(currentMonth).tz("Europe/Paris").format("MMMM YYYY").substring(1)}`}</Text>
                    <Text style={styles.chevrons}>
                        <Pressable  onPress={(e) => {e.preventDefault();executeNextMonth()}} >
                            <Feather name="chevrons-right" style={styles.monthFilterText} />
                        </Pressable>
                    </Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.dateInitiale}>L</Text>
                    <Text style={styles.dateInitiale}>M</Text>
                    <Text style={styles.dateInitiale}>M</Text>
                    <Text style={styles.dateInitiale}>J</Text>
                    <Text style={styles.dateInitiale}>V</Text>
                    <Text style={styles.dateInitiale}>S</Text>
                    <Text style={styles.dateInitiale}>D</Text>
                </View>
                {loading ? <View style={styles.loading}><Text style={styles.loadingText}>Chargement...</Text></View>:<></>}
                {loading ? <></> :
                weeks.map((week, key) => {
                    return (
                        <View   style={styles.row}
                                key={`CalendrierSemaine${key}`}>
                            {week.map((d, key) => {
                                return (
                                    <JourDuCalendrier 
                                        key={`${key}WeekOfCalendar${d.day}`} 
                                        day={d.day}
                                        date={d.date}
                                        today={d.today}
                                        past={d.past}
                                        occupied={d.occupied}
                                        checkin={d.checkin}
                                        checkout={d.checkout}
                                        taches={d.taches}
                                        isSelectable={isSelectable}
                                        shadowed={addTask ? !moment(selectedDate).tz("Europe/Paris").isSame(moment(d.date).tz("Europe/Paris"), "day") : (selectedDate !== undefined && !moment(selectedDate).tz("Europe/Paris").isSame(moment(d.date).tz("Europe/Paris"), "day"))}
                                        selected={addTask ? moment(selectedDate).tz("Europe/Paris").isSame(moment(d.date).tz("Europe/Paris"), "day") : (selectedDate !== undefined && moment(selectedDate).tz("Europe/Paris").isSame(moment(d.date).tz("Europe/Paris"), "day"))}
                                        onSelection={() => {onSelection(d.date)}} />
                                    
                                )
                            })  }
                        </View>
                            );
                        })
                        
                }
    
            </View>
        );
    }
    

}
const styles = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "column",
        width: "90%",
        shadowColor: css_black,
        shadowOffset: {width: 0, height: 0},
        shadowOpacity: 0.3,
        shadowRadius: 5,
        borderRadius: "15px",
        marginHorizontal: "5%"
    },
    row: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        marginVertical: "1vh",
        alignItems: "center"
    },
    dateInitiale: {
        width: "calc(100% / 7)",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        cursor: "default",
        color: css_calendar_font_grey
    },
    monthInitiale: {
        width: "calc(100% * 3 / 7)",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        cursor: "default",
        fontWeight: 700,
        color: css_grey
    },
    chevrons: {
        width: "calc(100% * 2/ 7)",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        cursor: "default",
        fontWeight: 700,
        color: css_darkBlue
    },
    loading: {
        display: "flex",
        width: "100%",
        justifyContent: "center"
    },
    loadingText: {
        textAlign: "center",
        fontSize: "16px",
        color: css_grey,
        marginVertical: "5vh"
    }
});



