import { del, get, post, put } from "../modules/ms-api.module";
import moment from 'moment-timezone';

const getLogements = async function() {
    const brut = await get({
        need_token: true,
        uri: "/logement/logements"
    });
    const data = await brut.json();
    if (brut.status === 200) return data.result;
    else return [];
}

/**
 * Récupérer la liste des logements attribués au token actuel.
 * @returns 
 */
const getAttributedLogements = async function ( seen_by = undefined, selectedWorkflow = -1, selectedEcheance = "none") {
    let brut = undefined;
    if (seen_by === undefined) {
        brut = await get({
            need_token: true,
            uri: "/logement/mes_logements_attribues",
            parameters: [
                {"filterEcheance": `${selectedEcheance}`},
                {"filterWorkflow": `${selectedWorkflow}`}
            ]
        });
    } else {
        brut = await get({
            need_token: true,
            uri: "/logement/mes_logements_attribues",
            parameters: [
                {"filterEcheance": `${selectedEcheance}`},
                {"filterWorkflow": `${selectedWorkflow}`},
                {"seen_by": seen_by}
            ]
        });
    }
    
    const data = (await brut.json()).result;
    if (brut.status !== 200) return [];

    const promises = [];
    for (let i = 0; i < data.logements.length; i++ ) {
        promises.push(logement_service.getPhoto(data.logements[i].id_logement));
    } 

    return await Promise.all(promises)
    .then((r) => {
        for (let i = 0; i < data.logements.length; i++) {
            data.logements[i].photo = r[i];
        }
        return data;
    })
    
}

/**
 * Récupérer la liste des informations liées au logement dont on a l'identifiant.
 * @returns 
 */
const getInformationsLogement = async function (id_logement, seen_by = undefined, beginDate = moment.tz("Europe/Paris"), endDate= moment.tz("Europe/Paris").add(1,'d')) {
    const parameters = [
        {"id_logement": `${id_logement}`}, 
        {"begin_date": `${moment(beginDate).tz("Europe/Paris").format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`}, 
        {"end_date": `${moment(endDate).tz("Europe/Paris").format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`}
    ];
    if (seen_by !== undefined) parameters.push({"seen_by": seen_by});
    const brut = await get({
        need_token: true,
        parameters: parameters,
        uri: "/logement/informations_logement"
    });
    const data = (await brut.json()).result;
    if (brut.status !== 200) return [];
    return await Promise.resolve(getPhoto(data.id_logement))
    .then((r) => {
        data.photo = r;
        return data;
    })
}

/**
 * Récupérer la photo officielle du logement.
 * @returns 
 */
const getPhoto = async function (id_logement) {
    const brut = await get({
        need_token: true,
        parameters: [{"id_logement": `${id_logement}`}],
        uri: "/logement/photo"
    });
    const data = await brut.blob();
    const imageUrl = URL.createObjectURL(data);
    if (brut.status === 200) return imageUrl;
    else return [];
}


const updateLogement = async function (
                                    libelle = "",
                                    numero_adresse = "", 
                                    type_voie_adresse = "", 
                                    nom_voie_adresse = "",
                                    complement_adresse = "", 
                                    ville_adresse = "", 
                                    code_postal_adresse = 0, 
                                    photo = "", 
                                    url_airbnb = "",  
                                    id_logement = undefined) {
    const brut = await put({
        need_token: true,
        parameters: [{"id_logement": `${id_logement}`}],
        body: {
            libelle: `${libelle}`,
            numero_adresse: `${numero_adresse}`,
            type_voie_adresse: `${type_voie_adresse}`,
            nom_voie_adresse: `${nom_voie_adresse}`,
            complement_adresse: `${complement_adresse}`,
            ville_adresse: `${ville_adresse}`,
            code_postal_adresse: `${code_postal_adresse}`,
            photo: `${photo}`,
            url_airbnb: `${url_airbnb}`
        },
        uri: '/logement/'
    });
    if (brut.status === 200) return true;
    else return false;                       
}

const createLogement = async function (
                                    libelle = "",
                                    numero_adresse = "", 
                                    type_voie_adresse = "", 
                                    nom_voie_adresse = "",
                                    complement_adresse = "", 
                                    ville_adresse = "", 
                                    code_postal_adresse = 0, 
                                    photo = "", 
                                    url_airbnb = "") {

    const brut = await post({
        need_token: true,
        body: {
            libelle: `${libelle}`,
            numero_adresse: `${numero_adresse}`,
            type_voie_adresse: `${type_voie_adresse}`,
            nom_voie_adresse: `${nom_voie_adresse}`,
            complement_adresse: `${complement_adresse}`,
            ville_adresse: `${ville_adresse}`,
            code_postal_adresse: `${code_postal_adresse}`,
            photo: `${photo}`,
            url_airbnb: `${url_airbnb}`
        },
        uri: '/logement/'
    });
    const data = await brut.json();
    if (brut.status === 200) return data.result;                    
}

const deleteLogement = async function (
    id_logement) {

    const brut = await del({
        parameters: [{"id_logement": `${id_logement}`}],
        need_token: true,
        uri: '/logement/'
    });
    if (brut.status === 200) return true;
    else return false;                       
}

const uploadPhoto = async function ({asset, id_logement}) {
    const brut = await post({
        need_token:true,
        uri: "/upload/",
        parameters:[{"id_logement":`${id_logement}`}],
        body: {
            uri: `${asset.uri.split(";base64,")[1]}`,               // The file's URI
            type: `${asset.type}`,             // The file's MIME type (e.g., 'image/jpeg')
            name: `${asset.fileName}`
        },
        on:"ms-photo"
    })
    if (brut.status === 200) return true;
    else return false;  

}
const logement_service = {deleteLogement, updateLogement, getInformationsLogement, getAttributedLogements, getLogements, getPhoto, uploadPhoto, createLogement };
export default logement_service;
