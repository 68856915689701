import { StyleSheet, View, Text, Image, LogBox } from 'react-native';
import { useEffect, useState } from 'react';
import { css_bg_grey, css_bg_red, css_darkBlue, css_green, css_grey, css_lightBlue, css_red, css_white } from '../../constants/Colors';
import InputComponent from '../InputComponent';
import Button from '../Button';
import logement_service from '../../services/logement.service';
import {launchImageLibrary} from 'react-native-image-picker';
import moment from 'moment-timezone';

export default function FormLogement({onBack, 
                                    toUpdate_libelle = "",
                                    toUpdate_numero_adresse = "", 
                                    toUpdate_type_voie_adresse = "", 
                                    toUpdate_nom_voie_adresse = "",
                                    toUpdate_complement_adresse = "", 
                                    toUpdate_ville_adresse = "", 
                                    toUpdate_code_postal_adresse = "", 
                                    toUpdate_photo = "", 
                                    toUpdate_url_airbnb = "",  
                                    toUpdate_id_logement = undefined }) {

    const [loading, setLoading] = useState(false);
    const [libelle, setLibelle] = useState("");
    const [numero_adresse, setNumeroAdresse] = useState("");
    const [type_voie_adresse, setTypeVoieAdresse] = useState("");
    const [nom_voie_adresse, setNomVoieAdresse] = useState("");
    const [complement_adresse, setComplementAdresse] = useState("");
    const [ville_adresse, setVilleAdresse] = useState("");
    const [code_postal_adresse, setCodePostalAdresse] = useState("");
    const [photo, setPhoto] = useState("default.png");
    const [alert, setAlert] = useState("");
    const [url_airbnb, setUrlAirbnb] = useState("");

    useState(() => {
        if (toUpdate_id_logement !== undefined) {
            setLibelle(toUpdate_libelle);
            setNumeroAdresse(toUpdate_numero_adresse);
            setTypeVoieAdresse(toUpdate_type_voie_adresse);
            setNomVoieAdresse(toUpdate_nom_voie_adresse);
            setComplementAdresse(toUpdate_complement_adresse);
            setVilleAdresse(toUpdate_ville_adresse);
            setCodePostalAdresse(toUpdate_code_postal_adresse);
            setPhoto(toUpdate_photo);
            setUrlAirbnb(toUpdate_url_airbnb);
        }
    }, [toUpdate_libelle, toUpdate_numero_adresse, toUpdate_type_voie_adresse, toUpdate_nom_voie_adresse, toUpdate_id_logement, toUpdate_complement_adresse, toUpdate_ville_adresse,toUpdate_code_postal_adresse, toUpdate_photo, toUpdate_url_airbnb])


    const pickImage = () => {
        
        const options = {
          mediaType: 'photo',
        };
      
        launchImageLibrary(options, (response) => {
          if (response.didCancel) {
            console.log('User cancelled image picker');
          } else if (response.error) {
            console.log('ImagePicker Error: ', response.error);
          } else {
            try {
                const asset = response.assets[0]; // Get the selected image asset
                const extension = asset.uri.split("image/")[1].split(";")[0];

                if (extension !== "png" && extension !== "jpg" && extension !== "jpeg") {
                    setAlert("L'image doit être au format : png, jpg ou jpeg.");
                } 
                else if (getBase64Size(asset.uri.split(";base64,")[1]) > 3 * 1024 * 1024) {
                    setAlert("L'image pèse plus de 3Mo, veuillez la compresser ou en choisir une autre plus légère.");
                }
                else {
                    asset.fileName = moment.tz("Europe/Paris").format('YYYYMMDDHHmmss')+"."+extension;
                    asset.type = "image/"+extension;
                    setPhoto(asset); // Save image asset to state
                    setAlert("");
                }
            } catch (err) {
                
                setAlert("Une erreur est survenue lors du téléchargement de l'image. Veuillez changer d'image ou réessayer plus tard.");
            }
            
          }
        });
      };

    const exec = () => {
        setLoading(true);
        if (toUpdate_id_logement !== undefined) {
            logement_service.updateLogement(libelle,numero_adresse,type_voie_adresse,nom_voie_adresse,complement_adresse,ville_adresse,code_postal_adresse, photo.fileName,url_airbnb,toUpdate_id_logement)
            .then((r) => {
                if (photo !== "default.png") {
                    logement_service.uploadPhoto({asset:photo ,id_logement: toUpdate_id_logement})
                    .then((r2) => {
                        setLoading(false);
                        onBack()
                    })
                } else {
                    setLoading(false)
                    onBack()
                }
            })
        } else {
            logement_service.createLogement(libelle,numero_adresse,type_voie_adresse,nom_voie_adresse,complement_adresse,ville_adresse,code_postal_adresse, photo.fileName,url_airbnb)
            .then((r) => {
                if (photo !== "default.png") {
                    logement_service.uploadPhoto({asset:photo ,id_logement:r})
                    .then((r2) => {
                        setLoading(false)
                        onBack()
                    })
                } else {
                    setLoading(false)
                    onBack()
                }
                
            })
        }
    }
    const getBase64Size = (base64String) => {
        // Remove the Base64 header (e.g., data:image/jpeg;base64,)
        const base64Data = base64String.split(';base64,').pop();
      
        // Calculate the size in bytes
        const sizeInBytes = (base64Data.length * 3) / 4;
      
        return sizeInBytes;
      };

    if (loading) return (<Text>Chargement en cours ...</Text>)
    return (
        <View   style={styles.ctn}>
            <View   style={styles.form_ctn}>
                <InputComponent libelle="Libellé du logement (*)" 
                                onChange={setLibelle}
                                disable={loading}
                                color={css_grey}
                                current_value={libelle}
                                />
                <InputComponent libelle="URL Airbnb (*)" 
                                onChange={setUrlAirbnb}
                                disable={loading}
                                color={css_grey}
                                current_value={url_airbnb}
                                />
                <InputComponent libelle="Ville de l'adresse (*)" 
                                onChange={setVilleAdresse}
                                disable={loading}
                                color={css_grey}
                                current_value={ville_adresse}
                                />
                <InputComponent libelle="Numéro de l'adresse" 
                                onChange={setNumeroAdresse}
                                disable={loading}
                                color={css_grey}
                                current_value={numero_adresse}
                                />  
                <InputComponent libelle="Type de voie (rue, avenue, ...)" 
                                onChange={setTypeVoieAdresse}
                                disable={loading}
                                color={css_grey}
                                current_value={type_voie_adresse}
                                />
                <InputComponent libelle="Nom de la voie" 
                                onChange={setNomVoieAdresse}
                                disable={loading}
                                color={css_grey}
                                current_value={nom_voie_adresse}
                                />
                <InputComponent libelle="Complément d'adresse" 
                                onChange={setComplementAdresse}
                                disable={loading}
                                color={css_grey}
                                current_value={complement_adresse}
                                />
                <InputComponent libelle="Code Postal" 
                                onChange={setCodePostalAdresse}
                                disable={loading}
                                color={css_grey}
                                current_value={code_postal_adresse}
                                />
                <Button title={toUpdate_id_logement !== undefined ? "Télécharger une nouvelle photo (3Mo max)" : "Télécharger une photo (3Mo max)"}
                        onPress={pickImage}
                        color={css_white}
                        background={css_darkBlue}
                        marginBottom={2}
                        fontWeight="700" />  
                {
                    photo !== "default.png" ?
                    
                        <View style={styles.uploadedPhoto}>
                          <Image
                            source={{uri: photo.uri}}
                            style={{width: 30, height: 30, resizeMode: 'contain', marginRight: "20px"}}
                          />
                          <Text>{photo.fileName}</Text>
                        </View>
                    :<></>
                }           
                {alert === "" ? <></>:<View style={styles.alerts}><Text>{alert}</Text></View>}
                    <Button onPress={exec}
                        title={loading ? "Connexion en cours ...": toUpdate_id_logement !== undefined ? "Mettre à jour le logement" : "Créer le logement"}
                        color={css_white}
                        background={css_green}
                        marginBottom={2}
                        fontWeight="700"
                        disabled={url_airbnb === "" || libelle === "" || ville_adresse === ""}
                        accessibilityLabel="Bouton de validation de la création du nouveau logement."
                    />

          </View>
            
        </View>
    );
    
}

const styles = StyleSheet.create({
    ctn: {
        marginTop: "3vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%"
    },
    form_ctn: {
        width:"90%"
    },
    search_ctn: {
        minWidth: "90%"
    },
    subtitle: {
        textAlign: "center"
    },
    uploadedPhoto: {
        backgroundColor: css_bg_grey,
        borderRadius: "15px",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "center",
        alignItems: "center"
    },
    alerts: {
        backgroundColor: css_bg_red,
        borderWidth: "1px",
        borderStyle: "solid",
        color: css_red,
        marginVertical: "2vh",
        paddingVertical: "2vh",
        borderColor: css_red,
        borderRadius: "15px",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "center",
        alignItems: "center"
    }
});