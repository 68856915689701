import { StyleSheet, View, Text, Pressable } from 'react-native';
import Button from '../Button';
import { useEffect, useState } from 'react';
import InputComponent from '../InputComponent';
import Titre from '../Titre';
import workflow_service from '../../services/workflow.service';
import { css_bg_green, css_bg_grey, css_black, css_green, css_grey, css_lightBlue, css_red, css_white } from '../../constants/Colors';
import { screenWidth } from '../../constants/Const';
import ClearSeparation from '../Elements/ClearSeparation';

export default function ManageWorkflow({onBack}) {
    const [loading, setLoading] = useState(true)
    const [workflows, setWorkflows] = useState([])
    const [selectedWorkflow, setSelectedWorkflow] = useState({
        id_workflow: -1
    })
    const [etapes, setEtapes] = useState([])
    const [ordreRetour, setOrdreRetour] = useState([])
    const [availableEtape, setAvailableEtape] = useState([])
    const [displayWBC, setDisplayWBC] = useState(false)
    const [valueInputWorkflow, setValueInputWorkflow] = useState("")
    const [displayEBC, setDisplayEBC] = useState(false)
    const [valueInputEtape, setValueInputEtape] = useState("")
    const [newDroits, setNewDroits] = useState ([])
    const [displayValidation, setDisplayValidation] = useState(false)
    const [hour, setHour] = useState("0");
    const [alertHourChanged,setAlertHourChanged] = useState(undefined)

    useEffect(() => {
        refresh()
    },[])

    useEffect(() => {
    }, [newDroits])

    const refresh = () => {
        setLoading(true)
        workflow_service.getWorkflows().then((res) => {
            setWorkflows(res)
            workflow_service.getAllEtapes().then((r) => {
                setEtapes(r)
                setLoading(false)
            })
            
        })
    }

    const updateDroit = (droit, id_role) => {
        const tmp = []
        newDroits.forEach((nd) => {
            if (nd.id_role === id_role) tmp.push({libelle: nd.libelle, id_role: id_role, droit: droit})
            else tmp.push({libelle: nd.libelle, id_role: nd.id_role, droit: nd.droit})
        })
        setNewDroits([]);
        setNewDroits(tmp)
    }

    const changeInputWorkflow = (value) => {
        setValueInputWorkflow(value)
    }

    const changeInputEtape = (value) => {
        setValueInputEtape(value)
    }

    const hour_changed = (value) => {
        const good = ['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23'];
        if (good.includes(value)) {
            setHour(value);
            setAlertHourChanged(undefined);
        }
        else {
            setHour(hour);
            setAlertHourChanged(`'${value}' n'est pas un horaire valide.`);
        }
    }

    const selectWorkflow = (workflow) => {
        setSelectedWorkflow(workflow)
        setOrdreRetour(workflow.etapes)
        setHour(workflow.heure_echeance)
        setAvailableEtape(etapes)
    }

    const ajout = (etape) => {
        setOrdreRetour( [ ...ordreRetour,  etape ])
    }

    const supprimer = (etape) => {
        const tmp = ordreRetour
        const newOrder = []
        tmp.forEach((res) => {
            if (etape.id_etape !== res.id_etape) newOrder.push(res)
        })
        setOrdreRetour(newOrder)
    }

    const tryCreationWorkflow = () => {
        setLoading(true)
        workflow_service.createWorkflow(valueInputWorkflow, newDroits).then((res) => {
            refresh()
            setDisplayWBC(false)
        })
    }
    
    const tryCreationEtape = () => {
        setLoading(true)
        workflow_service.createEtape(valueInputEtape).then((res) => {
            refresh();
            setDisplayEBC(false)
        })
    }

    const tryUpdate = () => {
        const result = {
            id_workflow: selectedWorkflow.id_workflow,
            etapes: ordreRetour
        }

        for (let i = 0; i< result.etapes.length; i++) {
            result.etapes[i].ordre = i+1
        }
        setLoading(true)
        workflow_service.updateWorkflow(result.id_workflow, result.etapes)
        .then((res) => {
            setLoading(false)
            launchValidationMessage()
        })
    }

    const tryHourChange = () => {
        setLoading(true);
        workflow_service.changeHour(selectedWorkflow.id_workflow, hour)
        .then((r) => {
            refresh();
        })
    }

    const launchValidationMessage = () => {
        setDisplayValidation(true);
        setTimeout(() => {
            setDisplayValidation(false);
        }, 3000)
    }

    const styles = StyleSheet.create({
        container: {
            marginTop: "5vh",
            width: "100%"
        },
        containerLogement: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: Math.min(screenWidth*0.9, 500*0.9),
            marginHorizontal: Math.min(screenWidth*0.05, 500*0.05),
            alignItems: "center",
            justifyContent: "center",
            marginVertical: "3vh"
        }, 
        create: {
            backgroundColor: css_green
        },
        createButtonText: {
            color: css_white
        },
        workflow: {
            display: "flex",
            flexDirection: "row",
            borderColor: css_grey,
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "15px",
            marginHorizontal: Math.min(screenWidth*0.01,500*0.01),
            marginVertical: "1vh",
            padding: "5px",
            justifyContent: 'center',
            alignItems: "center"
        },
        selectedWorkflow: {
            display: "flex",
            flexDirection: "row",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "15px",
            marginHorizontal: Math.min(screenWidth*0.01,500*0.01),
            marginVertical: "1vh",
            marginHorizontal: Math.min(screenWidth*0.01,500*0.01),
            marginVertical: "1vh",
            padding: "5px",
            justifyContent: 'center',
            borderColor: css_green,
            backgroundColor: css_bg_green
        },
        typeDroit: {
            display: "flex",
            flexDirection: "row",
            borderColor: css_grey,
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "15px",
            marginHorizontal: Math.min(screenWidth*0.01,500*0.01),
            marginVertical: "1vh",
            paddingHorizontal: Math.min(screenWidth*0.02,500*0.02),
            paddingVertical: "2vh",
            justifyContent: 'center'
        },
        selectedTypeDroit: {
            display: "flex",
            flexDirection: "row",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "15px",
            marginHorizontal: Math.min(screenWidth*0.01,500*0.01),
            marginVertical: "1vh",
            paddingHorizontal: Math.min(screenWidth*0.02,500*0.02),
            paddingVertical: "2vh",
            justifyContent: 'center',
            borderColor: css_green,
            backgroundColor: css_bg_green
        },
        backButton: {
            width: "20%", 
            position: "absolute",
            zIndex: "1"
        },
        containerTitle: {
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row"
        },
        title: {
            width: "100%", 
            display: "flex",
            justifyContent: "center",
            fontSize: "1.2rem",
            marginBottom:"5%"
        },
        creationButton: {
            width: "40%",
            marginLeft: "30%",
            marginVertical: "2vh"
        },
        icon: {
            width: "50px",
            height: "50px",
        },
        titlesWorkflow: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column"
        },
        add: {
            borderRadius: "15px",
            backgroundColor: css_green,
            height: Math.min(screenWidth*0.05,500*0.05),
            paddingHorizontal: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: Math.min(screenWidth*0.02,500*0.02)
        },
        addText: {
            color: css_white,
            fontWeight: "600"
        },
        delete: {
            borderRadius: "15px",
            backgroundColor: css_red,
            height: Math.min(screenWidth*0.05,500*0.05),
            paddingHorizontal: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: Math.min(screenWidth*0.02,500*0.02)
        },
        deleteddText: {
            color: css_white,
            fontWeight: "600"
        },
        containerEtapesResult: {
            display: "flex",
            flexDirection: "row",
            borderRadius: "15px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: css_grey,
            paddingHorizontal: Math.min(screenWidth*0.01,500*0.01),
            paddingVertical: "1vh",
            marginHorizontal: Math.min(screenWidth*0.01,500*0.01),
            marginVertical: "1vh"
        },
        containerOrder: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "center"
        },
        containerFormCreate: {
            width: Math.min(screenWidth*0.9,500*0.9),
            marginHorizontal: Math.min(screenWidth*0.05,500*0.05),
            display: "flex",
            marginVertical: "2vh",
        },
        resultat: {
            backgroundColor: css_bg_grey,
            width: Math.min(screenWidth*0.9, 500*0.9),
            marginHorizontal: Math.min(screenWidth*0.05, 500*0.05),
            paddingHorizontal: "10%",
            paddingVertical: "5px",
            flexDirection: "column",
            display: "flex",
            borderRadius: "15px"
        },
        containerValidationButton: {
            width: Math.min(screenWidth*0.9, 500*0.9),
            marginHorizontal: Math.min(screenWidth*0.05, 500*0.05),
            paddingVertical: "10px",
            marginVertical: "3vh"
        },
        containerDroitButton: {
            width: "60%",
            display: "flex",
            flexDirection: "row"
        },
        containerDroit: {
            width: "100%",
            display: "flex",
            flexDirection: "row"
        },
        containerDroitText: {
            width: "25%"
        },
        check: {
            width: Math.min(screenWidth*0.3,500*0.3),
            height: Math.min(screenWidth*0.3,500*0.3),
            margin: Math.min(screenWidth*0.05,500*0.05)
        },
        checkContainer: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center"
        }
    });


    return (loading ? <><Text>Chargement en cours ...</Text></> :
        <View style={styles.container}>
            
            {!displayValidation ? <></>:
                <View style={styles.checkContainer}>
                <Titre  texte="Le workflow a été mis à jour !"
                        size={1.4} />
              </View>}
            
                <View style={styles.containerLogement} >
                <Titre  texte="Sélectionnez le workflow à modifier"
                        marginLeft={5} 
                        size={1.1}
                        color={css_black}
                        marginBottom={2} />
        
                {workflows.map((w, key) => {
                    return (
                        <Pressable onPress={() => {selectWorkflow(w.id_workflow === selectedWorkflow.id_workflow ? {id_workflow: -1} : w)}} key={`ManageWorkflowWorkflow${key}`}>
                            <View style={w.id_workflow === selectedWorkflow.id_workflow ? styles.selectedWorkflow : styles.workflow}>
                                <Text   style={{color: w.id_workflow === selectedWorkflow.id_workflow ? css_green : css_grey}}>{w.libelle}</Text>
                            </View>
                        </Pressable>    
                    )
                })}
                <Pressable onPress={() => {setDisplayWBC(!displayWBC)}}>
                    <View style={[styles.workflow, styles.create]}>
                        <Text style={styles.createButtonText}>{displayWBC ? "Retour à la liste des workflows" : "Créer un nouveau workflow"}</Text>
                    </View>
                </Pressable>
            </View>
            
            { selectedWorkflow.id_workflow !== -1 ?
                <>
                    <ClearSeparation />
                    <View style={styles.containerLogement} >
                        <Titre  texte="Heure d'échéance du workflow"
                                marginLeft={5} 
                                size={1.1}
                                color={css_black}
                                marginBottom={2} />
   
                        <InputComponent libelle={`Horaire actuel : ${selectedWorkflow.heure_echeance}`} 
                                onChange={hour_changed}
                                disable={loading}
                                disable_libelle={true}
                                color={css_grey}
                                current_value={hour}
                                />

                        {alertHourChanged === undefined ? 
                        <View style={styles.containerValidationButton}>
                            <Button title="Mettre à jour l'horaire d'échéance"
                                    background={css_green}
                                    color={css_white}
                                    fontWeight={600}
                                    onPress={tryHourChange}
                                />
                        </View>:
                        <View>
                            <Text style={[{color: css_red}]}>{alertHourChanged}</Text>
                        </View>}
                    </View>
                </>:<></>}

            { displayWBC ? 
            <>
                <ClearSeparation />
                <View style={styles.containerFormCreate}>
                    <InputComponent     libelle="Nom du nouveau workflow"
                                        inputType="text"
                                        color={css_grey}
                                        onChange={changeInputWorkflow} />
                </View>
                <View style={styles.containerValidationButton}>
                    <Button title="Créer le workflow"
                            background={css_green}
                            color={css_white}
                            fontWeight={600}
                            onPress={tryCreationWorkflow}
                        />
                </View>
            </>    :<></>}
            
            {selectedWorkflow.id_workflow === -1 || displayWBC ? <></> :
                <>
                <ClearSeparation />
                <View style={styles.containerLogement} >
                <Titre  texte="Sélectionnez les étapes dans l'ordre"
                        marginLeft={5} 
                        size={1.1}
                        color={css_black}
                        marginBottom={2} />
                {availableEtape.map((e, key) => {
                    return (
                        <View style={styles.workflow} key={`ManageWorkflowAvailable${key}`}>
                            <Text>{e.libelle}</Text>
                            <Pressable onPress={() => {ajout(e)}}>
                                <View style={styles.add}>
                                    <Text style={styles.addText}>+ Ajouter</Text>
                                </View>
                            </Pressable>
                        </View> 
                    )
                })}
                <Pressable onPress={() => {setDisplayEBC(!displayEBC)}}>
                    <View style={[styles.workflow, styles.create]}>
                        <Text style={styles.createButtonText}>{displayEBC ? "Retour à la liste des étapes" : "Créer une nouvelle étape"}</Text>
                    </View>
                </Pressable>
            </View></>}

            { displayEBC && !displayWBC ? 
            <>
                <ClearSeparation />
                <View style={styles.containerFormCreate}>
                    <InputComponent     libelle="Nom de la nouvelle étape"
                                        inputType="text"
                                        color={css_grey}
                                        onChange={changeInputEtape} />
                </View>
                <View style={styles.containerValidationButton}>
                    <Button title="Créer l'étape"
                            background={css_green}
                            color={css_white}
                            fontWeight={600}
                            onPress={tryCreationEtape}
                        />
                </View>
            </>:<></>}
            
            {selectedWorkflow.id_workflow === -1 || ordreRetour.length === 0 || displayWBC || displayEBC ? <></> :
            <View style={styles.resultat}>
                <Text><b>Résumé : </b>{selectedWorkflow.libelle}</Text>
                <View style={styles.containerOrder}>
                    {ordreRetour.map((e, key) => {
                        return (
                            <View   style={styles.workflow} 
                                    key={`ManageWorkflowResume${key}`}>
                                <Text>{`${key+1} : ${e.libelle}`}</Text>
                                <Pressable onPress={()=> {supprimer(e)}}>
                                    <View style={styles.delete}>
                                        <Text style={styles.deleteddText}>- Retirer</Text>
                                    </View>
                                </Pressable>
                            </View>
                        )
                    })}
                </View>
            </View>}

            {selectedWorkflow.id_workflow === -1 || ordreRetour.length === 0 || displayWBC || displayEBC? <></> :
            <View style={styles.containerValidationButton}>
                <Button title="Mettre à jour le workflow"
                        background={css_green}
                        color={css_white}
                        fontWeight={600}
                        onPress={tryUpdate}
                     />
            </View>}
            
        </View>
    );
    
}


