import { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Input } from 'react-native-elements';
import { css_darkBlue, css_black, css_grey, css_white } from '../constants/Colors';
import Titre from './Titre';
import { screenWidth } from '../constants/Const';

export default function InputComponent({libelle, onChange, disable_libelle = false,inputType, disable, current_value = undefined, color = css_black, background = css_white}) {
    const [placeholder, setPlaceholder] = useState("Pas de placeholder fourni.");
    const [value, setValue] = useState("");
    const [type, setType] = useState("text");
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setPlaceholder(libelle)
    }, [libelle])
    

    useEffect(() => {
        setType(inputType)
    }, [inputType])

    useEffect(() => {
        setDisabled(disable)
    }, [disable])

    useEffect(() => {
        if (current_value !== undefined) {
            setValue(current_value);
        }
    }, [current_value])
    const valueChanged = (e) => {
        setValue(e.target.value)
        onChange(e.target.value)
    }

    const changeType = () => {
        if (type === "password") setType("passwordCleared")
        else if (type === "passwordCleared") setType("password")
    }

    const styles = StyleSheet.create({
        container: {
            marginTop: "2vh",
            marginBottom: "2vh",
            width: Math.min(screenWidth*0.9, 500*0.9)
        },
        input: {
            borderRadius: "10px",
            borderWidth: "1px",
            borderColor: color,
            shadowColor: css_grey,
            shadowOffset: {width: 0, height: 0},
            shadowOpacity: 0.2,
            shadowRadius: 5,
            outlineStyle: 'none',
            backgroundColor: background
        },
        inputContainerStyle: {
            borderBottomWidth: 0,
            paddingTop:"1vh",
            paddingBottom: "1vh",
            textAlign: "center",
            outlineStyle: 'none',
            width:"100%"
        },
        error: {
            margin:0
        }
    });
    

    return (
        <View style={styles.container}>

            {!disable_libelle ?
            <Titre  texte={placeholder} 
                    color={color}
                    size={1.2} />
                    :<></>}
            <Input  placeholder={placeholder} 
                    value={value} 
                    containerStyle={styles.input}
                    inputContainerStyle={styles.inputContainerStyle}
                    errorStyle={styles.error}
                    onChange={valueChanged}
                    secureTextEntry={type === "password"}
                    rightIcon={type === "password" ? { type: 'material-icons', name: 'visibility', onPress: changeType  } : { type: 'material-icons', name: 'visibility-off', onPress: changeType  }}
                    rightIconContainerStyle={type === "password" || type === "passwordCleared" ? {} : {display: "none"}}
                    disabled={disabled}
                    
            />
        </View>
        
    );
}

