import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { StyleSheet, View} from 'react-native';
import Workflow from '../Elements/Workflow';

export default function ListeWorkflows({idLogement, filterWorkflow, programmation = [], refresh = null, setSelectedAction = null , setLoadingProgrammation =  null, loadingProgrammation = -1,setSelectedTache = null, selectedTache = undefined }) {
    const [tachesOfTheDay, setTachesOfTheDay] = useState([]);
    const [finalObject, setFinalObject] = useState({
        date_debut: moment.tz("Europe/Paris"),
        date_fin: moment.tz("Europe/Paris"),
        id_logement: -1
    })
    useEffect(() => {
        filtering()
    }, [ programmation, filterWorkflow])

    useEffect(() => {
        let tmp = finalObject;
        tmp.id_logement = idLogement;
        setFinalObject(tmp)
    }, [idLogement])

    const filtering = () => {
        let tmp = []
        programmation.forEach((p) => {
            if (p.id_workflow === filterWorkflow || filterWorkflow === -1) {
                tmp.push(p)
            }
        })
        setLoadingProgrammation(-1)
        setTachesOfTheDay(tmp)
    }


    const styles = StyleSheet.create({
        containerWorkflow: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
        }
    });
    
    return (
       <View>
        { tachesOfTheDay.length !== 0 ? 
            <>
            {tachesOfTheDay.map((t, key) => {
                return(
                    <View   key={`GestionCalendrierTaches${t.id_workflow}${key}`}
                            style={styles.containerWorkflow}>
                        <Workflow   destinataires={t.destinataire}
                                    color={t.case}
                                    date_change={t.date_change}
                                    date_echeance={t.date_echeance}
                                    libelle={t.workflow}
                                    nom_createur={t.utilisateur}
                                    validateur={t.validateur}
                                    loading={loadingProgrammation === t.id_programmation}
                                    onBack={() => {setSelectedTache(undefined); setSelectedAction(undefined)}}
                                    onNotification={() => {setSelectedTache(selectedTache === undefined ? t : t.id_workflow === selectedTache.id_workflow ? undefined : t); setSelectedAction("notify")}}
                                    onValidation={() => {setSelectedTache(selectedTache === undefined ? t : t.id_workflow === selectedTache.id_workflow ? undefined : t); setSelectedAction("next")}}
                                    onDeletion={() => {setSelectedTache(selectedTache === undefined ? t : t.id_workflow === selectedTache.id_workflow ? undefined : t); setSelectedAction("delete")}}    />
                        
                    </View>
                )
                
            })}
            
            </> 
        : <></>}
       </View>
    );
}

